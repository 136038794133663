<template>
  <section>
    <h1>Ryan Tomlinson</h1>
    <p><a href="https://www.linkedin.com/in/ryanptomlinson/" target="_blank">LinkedIn</a> | <a href="https://github.com/yamlinson" target="_blank">GitHub</a></p>
  </section>
  <br>
  <div class="main">
  <section>
    <h2>Professional Experience</h2>
      <hr>
      <p><b>IT Security Engineer III - Security Operations</b> | IT Security Office</p>
      <p><b>Cornell University</b> | Ithaca, NY (Remote)</p>
      <p><i>December 2022 - Present</i></p>
      <ul>
          <li>Developed and maintained automation tools in Python, Javascript</li>
          <li>
            Tested web applications for known vulnerabilities, including the OWASP Top Ten; 
            assisted development teams in remediation
          </li>
          <li>Led efforts to define configuration of long-lived, “pet” servers in Ansible IaC</li>
          <li>Led efforts to automate code deployment pipelines via AWS CodeCommit and CodeDeploy</li>
          <li>Directed self-phishing campaigns; provided training to users with diverse technical backgrounds</li>
          <li>Monitored and investigated possible indicators of compromise based on log data aggregated to Splunk</li>
          <li>Managed central firewall for Ithaca campus</li>
      </ul>
      <br>
      <p><b>Information Systems Administrator</b> | University Library</p>
      <p><b>Idaho State University</b> | Pocatello, ID</p>
      <p><i>October 2021 - November 2022</i></p>
      <ul>
          <li>Shifted local server infrastructure from bare-metal to virtualized and containerized</li>
          <li>Developed and maintained PHP application which simplified database access and administration for librarians</li>
          <li>
            Developed and implemented automation strategies for workstation deployment and configuration management, 
            including local Windows automation via PowerShell
        </li>
          <li>Installed and configured web servers, databases, and forward and reverse proxies</li>
          <li>Implemented centralized internal knowledge base and asset management systems</li>
      </ul>
      <br>
      <p><b>IT Operations and Support Analyst I</b> | Central IT Services</p>
      <p><b>Idaho State University</b> | Pocatello, ID</p>
      <p><i>March 2021 - October 2021</i></p>
      <ul>
          <li>
            Lead desktop support team, supervising and coaching about 8 student technicians and 
            acting as a final central escalation point for staff and faculty workstation issues
        </li>
      </ul>
      <br>
      <p><b>Various Part-Time IT Support Roles</b> | Central IT Services</p>
      <p><b>Idaho State University</b> | Pocatello, ID</p>
      <p><i>November 2019 - March 2021</i></p>
  </section>
  <br>
  <section>
      <h2>Education</h2>
      <hr>
      <p><b>Bachelor of Science</b> | Software Engineering</p>
      <p><b>Western Governors University</b> | Millcreek, UT</p>
      <p><i>April 2024</i></p>
      <ul>
        <li>Developed and deployed Java Spring Boot applications via Docker containers on AWS</li>
      </ul>
      <br>
      <p><b>Bachelor of Music</b> | Music Performance</p>
      <p><b>Idaho State University</b> | Pocatello, ID</p>
      <p><i>December 2020</i></p>
  </section>
  <br>
  <section>
      <h2>Technical Certifications</h2>
      <hr>
      <table>
          <tr>
              <th><b>Name</b></th>
              <th><b>ID</b></th>
              <th><b>Active Through</b></th>
          </tr>
          <tr>
              <td>CompTIA Project+</td>
              <td><a href="http://verify.CompTIA.org" target="_blank">GP7CLT65MEB4Q139</a></td>
              <td>N/A</td>
          </tr>
          <tr>
              <td>AWS Certified Cloud Practitioner</td>
              <td><a href="https://aws.amazon.com/verification" target="_blank">6KWSDCW18141QGC3</a></td>
              <td>June 2026</td>
          </tr>
          <tr>
              <td>CompTIA Security+</td>
              <td><a href="http://verify.CompTIA.org" target="_blank">YYMGZ2MP0KFQQ999</a></td>
              <td>April 2025</td>
          </tr>
          <tr>
              <td>Microsoft Certified Security Operations Analyst Associate</td>
              <td>991354549</td>
              <td>May 2024</td>
          </tr>
          <tr>
              <td>CompTIA A+</td>
              <td><a href="http://verify.CompTIA.org" target="_blank">6Q3BBDSPZPQE1BW9</a></td>
              <td>April 2025</td>
          </tr>
      </table>
  </section>
  <br>
  <section>
      <h2>Personal Projects / Home Network</h2>
      <hr>
      <p><b>Systems and services</b></p>
      <ul>
          <li>Automated machine configuration and deployment with Ansible, Cloud Init, Terraform, Packer</li>
          <li>Clustered virtualization in Proxmox VE</li>
          <li>Containerized workloads with Docker, Helm, and K3s Kubernetes</li>
          <li>Check out the public portion of my local IaC <a href="https://github.com/ryanptomlinson/infra-local">on GitHub</a></li>
      </ul>
      <p><b>Security and networking</b></p>
      <ul>
          <li>Automated secret management with HashiCorp Vault</li>
          <li>Firewall configuration, routing, and network segmentation in pfSense</li>
          <li>VLAN configuration and port security in managed switches</li>
          <li>Local DHCP, DNS, and NAT; Cloudflare DNS and proxy tunneling</li>
      </ul>
  </section>
</div>
</template>

<style scoped>
ul {
  list-style-type: disc;
}
table {
  width: 100%;
}
table, th, td {
    border-collapse: collapse;
    border: 1px solid #111111;
}
th, td {
    padding: 5px;
}
th {
    font-weight: 500;
}
b, strong {
    color: #000000;
}
h1 {
    font-size: 200%;
}
h2 {
    font-size: 120%;
}
</style>
<script>

export default {
  name: 'HomeView',
  components: {
  }
}
</script>
