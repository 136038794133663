<template>
  <nav>
    <router-link to="/">Resume</router-link> |
    <router-link to="/bio">About Me</router-link> |
    <router-link to="/about">About This Page</router-link>
  </nav>
  <router-view/>
</template>

<style>
body {
    background-color: #151a29;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 900px;
  margin: auto;
  padding: 5%;
  background-color: #eeeeee;
  color: #121212;
  text-align: center;
}

.main {
  text-align: left;
}

nav {
  font-size: large;
}
nav .router-link-active {
  color: #121212;
}

a {
  font-weight: bold;
  text-decoration: none;
  color: #206cc4;
}
a:hover, a:active {
  color: #248aff;
}
</style>
